import useAccountStore from '@/stores/accountStore'
import usePersonStore from '@/stores/personStore'
import useWorkspaceStore from '@/stores/workspaceStore'
import * as amplitude from '@amplitude/analytics-browser'

const AMPLITUDE_API_KEY = 'b9477df19dea91fb382f82aa2277cbc'

function amplitudeInit (): void {
  const accountStore = useAccountStore()
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: false,
    autocapture: { elementInteractions: true }
  })
  amplitude.setUserId(accountStore.getAccountEmail)
}

function amplitudeTrack (event: string, prop: object): void {
  const workspaceStore = useWorkspaceStore()
  const personStore = usePersonStore()

  const properties = {
    personId: personStore.getPersonId,
    workspace: workspaceStore.getWorkspaceName,
    team: workspaceStore.getWorkspaceTeamName,
    source: 'front#portal-configurations',
    ...prop
  }

  amplitude.track(event, properties)
}

export {
  amplitudeTrack,
  amplitudeInit
}

export default {
  install (): void {
    amplitudeInit()
  }
}
